<template>
  <v-list three-line>
    <v-list-item-group>
      <v-expansion-panels flat>
        <v-expansion-panel flat
                           @change="changeForecastView(f)"
        >
          <v-expansion-panel-header class="ma-1 py-2">
            <v-list-item :key="f.city.codigo_ibge">
              <template>
                <v-row>
                  <v-flex xs2 sm2 md2>
                    <v-list-item-icon>
                      <camping-score-icon :camping-score="f.camp_score">
                      </camping-score-icon>
                    </v-list-item-icon>
                  </v-flex>
                  <v-flex xs7 sm8 md7>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ f.city.nome }}
                        <span class="grey--text">
                        ({{ f.city.uf }})
                      </span>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <chip-camping-score :f="f.camp_score" :label="f.camp_score_descrition">
                        </chip-camping-score>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="this.directions || this.loadingDirections">
                        <v-progress-circular indeterminate v-if="this.loadingDirections">
                        </v-progress-circular >
                        <div>
                          <v-icon>mdi-map-search-outline</v-icon>
                          {{ this.directions.routes[0].summary.distance.toFixed() }} km
                        </div>
                        <div>
                          <v-icon>mdi-clock-outline</v-icon>
                          {{ (this.directions.routes[0].summary.duration / 60 / 60).toFixed(1) }} horas
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-flex>
                  <v-flex xs3 sm2 md3>
                    <v-list-item-action>
                      <!--
                                          <v-list-item-action-text v-text="f.city.nome">
                                          </v-list-item-action-text>
                      -->
                      <v-spacer></v-spacer>
                      <v-btn rounded outlined color="blue" @click="searchCampingsGoogleMaps">
                        <v-icon>mdi-map-search-outline</v-icon>
                        <div v-if="$vuetify.breakpoint.mdAndUp">
                          Campings
                        </div>
                      </v-btn>
                      <v-btn rounded outlined color="blue" @click="openDetailPrediction">
                        <v-icon>mdi-weather-cloudy-clock</v-icon>
                        <div v-if="$vuetify.breakpoint.mdAndUp">
                          Detalhes
                        </div>
                      </v-btn>

                    </v-list-item-action>
                  </v-flex>
                </v-row>

              </template>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-0">
              <v-col>
                <v-divider></v-divider>
                <v-row class="mt-1">
                  <div class="pa-1 text-center mx-auto" v-for="d in f.daily" :key="f.city.nome+d.dt">
                    <v-col
                        class="grey--text mx-0"
                    >
                      <v-row class="center">
                        {{ new Date(d.dt * 1000).toLocaleString('pt-br', { weekday: "short" }) }}
                      </v-row>
                      <v-row>
                        <img width="32px" class="img-center" alt="icon" :src="getIcon(d.weather[0].icon)">
                      </v-row>
                      <v-row class="center">
                        <div class="v-size--small text-caption" v-if="d.rain !== undefined">
                          {{ d.rain.toFixed() }}mm
                          <span style="color: deepskyblue" class="v-color-picker__hue">
                            ({{ (d.pop * 100).toFixed() }}%)
                          </span>
                        </div>
                        <div class="v-size--small text-caption" v-else>
                          -
                        </div>
                      </v-row>
                      <v-row class="center">
                        <v-chip
                            color="lighten-4"
                            label
                            x-small
                        >
                          {{ d.temp.min.toFixed(0) }} ~ {{ d.temp.max.toFixed(0) }}°C
                        </v-chip>
                        <div class="v-size--small text-caption">
                          <v-icon small :color="(d.wind_gust>25) ? 'red':''">mdi-weather-windy</v-icon>
                          {{ d.wind_gust.toFixed(0) }} km/h
                        </div>
                      </v-row>
                    </v-col>
                  </div>
                </v-row>
                <v-row class="mt-3">
                  <v-col>
                  <v-divider></v-divider>
                  <div v-if="this.contexts" class="text-caption ma-2">
                    <vue-markdown>{{this.contexts.text}}</vue-markdown>
                  </div>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>

    </v-list-item-group>
  </v-list>
</template>
<script>

import CampingScoreIcon from "@/components/CampingScoreIcon";
import openrouteservice from "openrouteservice-js";
import ChipCampingScore from "@/components/ChipCampingScore";
import VueMarkdown from 'vue-markdown-v2';
import axios
  from "axios";

export default {
  name: 'city-forecast-card-h-2',
  components: {ChipCampingScore, CampingScoreIcon, VueMarkdown},
  props: {
    f: { type: Object },
    location: null
  },
  data: () => ({
    show: true,
    directions: null,
    contexts: null,
    loadingDirections: false
  }),
  mounted () {
    console.log(this.$props.f.camp_score)
    if (this.$props.f.camp_score >= 90) {
      this.changeForecastView(this.f.city)
    }
  },
  methods: {
    getIcon (icon) {
      return require('../assets/icons/' + icon + '.png')
    },
    openDetailPrediction () {
    // src="<iframe width="650" height="450" src="https://embed.windy.com/embed2.html?lat=${ this.f.city.latitude }&lon={ this.f.city.longitude }&detailLat=-31.015&detailLon=-52.053&zoom=7&level=surface&overlay=rain&product=ecmwf&menu=&message=true&marker=&calendar=24&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1" frameborder="0"></iframe>
      const baseUrl = `https://embed.windy.com/embed2.html?lat=${ this.f.city.latitude }&lon=${ this.f.city.longitude }&detailLat=-31.015&detailLon=-52.053&zoom=10&level=surface&overlay=rain&product=ecmwf&menu=&message=true&marker=&calendar=24&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`
      window.open(baseUrl, '_blank');
    },
    searchCampingsGoogleMaps () {
      // https://www.google.com/maps/search/camping/@-27.0697526,-49.5422611,9.65z/data=!4m7!2m6!3m5!1scamping!2s-26.9155,+-51.1459!4m2!1d-49.0709!2d-26.9155
      const baseUrl = `https://www.google.com/maps/search/camping/@${ this.f.city.latitude },${ this.f.city.longitude },9.65z/data=!4m7!2m6!3m5!1scamping!2s${ this.f.city.latitude },+${ this.f.city.longitude }!4m2!1d${ this.f.city.longitude }!2d${ this.f.city.latitude }`
      window.open(baseUrl, '_blank');
    },
    changeForecastView (f) {
      // console.log(`cheguei`, f)
      if (this.directions !== null) return;
      this.getDirections(f.city)
      if (this.contexts !== null) return;
      this.getContext(f.city)
    },
    getDirections (city) {
      if (this.location === null) return
      this.loadingDirections = true
      const orsTokens = [
        '5b3ce3597851110001cf6248568ce9256b774686a8dfa0aeb9289677', //github julio
        '5b3ce3597851110001cf62484df850bd9d274b7095613acdc3465dbf', // mariajurema
        '5b3ce3597851110001cf6248de7dc217c6f648648a547aea1c0eea89' //rafaalbuquer
      ]
      const orsToken = orsTokens[Math.floor(Math.random() * orsTokens.length)]
      const Directions = new openrouteservice.Directions(
          { api_key: orsToken }
      )
      const _this = this
      Directions.calculate({
        coordinates: [
          [this.location.coords.longitude, this.location.coords.latitude],
          [city.longitude, city.latitude]
        ],
        profile: "driving-car",
        format: "json",
        language: "pt",
        preference: "recommended",
        units: "km"
      })
          .then(function (json) {
            _this.directions = json
          _this.loadingDirections = false
          })
          .catch(function (err) {
            console.error(err);
            _this.loadingDirections = false
          })
      return 0
    },
    getContext(city) {
      const data = {
        city: city.nome,
        state: city.uf,
        start_at:  localStorage.getItem('startDate'),
        end_at: localStorage.getItem('endDate'),
      };
      // Make the GET request with Axios

      axios.get(`https://api.acampe.me/webhook/acampe-context`,
          {params: data}).then( (res) => {
            console.log('Context:', res.data.text);
            this.contexts = res.data
          }
      );

      // console.log('TEXTO:', res.text);

    }
  }
}
</script>
<style scoped>
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0;
}

.theme--light.v-list-item:hover::before {
  opacity: 0;
}

</style>
